import * as React from 'react';

class ErrorHandling extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
  }

  renderErrorUI() {
    return (
      <div
        style={{
          backgroundColor: '#272727',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: '20px',
          zIndex: 1000,
          fontFamily: 'system-ui, -apple-system, sans-serif'
        }}
      >
        <div style={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          backgroundColor: 'rgba(240, 185, 11, 0.2)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px'
        }}>
          <span style={{
            fontSize: '30px',
            color: '#f0b90b'
          }}>!</span>
        </div>

        <h1 style={{ 
          color: 'white', 
          textAlign: 'center',
          margin: '0 0 10px 0',
          fontSize: '24px',
          fontWeight: '600'
        }}>
          Oops, something went wrong
        </h1>

        <p style={{ 
          color: '#b0b0b0', 
          textAlign: 'center',
          maxWidth: '450px',
          margin: '0 0 25px 0',
          lineHeight: '1.4',
          fontSize: '15px'
        }}>
          We encountered an unexpected error.
        </p>

        <div style={{
          padding: '12px 25px',
          borderRadius: '6px',
          backgroundColor: 'rgba(240, 185, 11, 0.15)',
          marginBottom: '25px',
          textAlign: 'center'
        }}>
          <p style={{
            color: '#f0b90b',
            fontSize: '16px',
            fontWeight: 'bold',
            margin: '0 0 3px 0'
          }}>
            Please refresh your browser
          </p>
          <p style={{
            color: '#d0d0d0',
            fontSize: '13px',
            margin: 0
          }}>
            Press F5 or CTRL+R (CMD+R on Mac)
          </p>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.hasError) {
      try {
        return this.renderErrorUI();
      } catch (renderError) {
        return (
          <div style={{ padding: '20px', fontFamily: 'sans-serif' }}>
            A critical error occurred. Please refresh the page.
          </div>
        );
      }
    }
    return this.props.children;
  }
}

export default ErrorHandling;